import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import {
    Container,
    Col,
} from "reactstrap";

// core components
import { Link } from "react-router-dom";
import { PrevBtn, NextBtn } from "components/Button/Button";
import axios from "axios";
import { getBrowser } from "getBrowser";

const settingsone = {
    useCSS: true,
    arrows: true,
    centerMode: false,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    swipeToSlide: true,
    rows: 1,
    adaptiveHeight: true,
    lazyLoad: true,
    prevArrow: <PrevBtn />,
    nextArrow: <NextBtn />,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        }
    ]
};

const settingstwo = {
    useCSS: true,
    arrows: true,
    centerMode: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    rows: 1,
    adaptiveHeight: true,
    lazyLoad: true,
    prevArrow: <PrevBtn />,
    nextArrow: <NextBtn />,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
};

function Body(props) {
    const [isDrag, setIsDrag] = useState()
    const vdoClick = (vod_id) => {
        if (isDrag) {
            return
        } else {
            window.location.href = `/vdo/${vod_id}`
        }
    }
    const continueClick = (id) => {
        if (isDrag) {
            return
        } else {
            props.setIsLoading(true)
            if (localStorage.getItem('auth')) {
                axios.post(`${process.env.REACT_APP_API_CONTENT2}/series`, { device: getBrowser() }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth')}`
                    },
                })
                    .then(result => {
                        const watchContinue = result.data.res_data.vod.filter(data => data.playlist_type == 'continue')
                        if (watchContinue[0]) {
                            // console.log(watchContinue[0].item.filter(fil => fil.vod_id == id))
                            const watchConUrl = watchContinue[0].item.filter(fil => fil.vod_id == id)
                            if (!watchConUrl[0]) {
                                window.location.href = `/vdo/${id}/ep/EP.1`
                                // window.open(`/vdo/${id}/ep/EP.1`)
                            } else {
                                window.location.href = `/vdo/${id}/ep/EP.${watchConUrl[0].ep_num}?play=${watchConUrl[0].current.slice(0, -3)}`
                                // window.open(`/vdo/${id}/ep/EP.${watchConUrl[0].ep_num}?play=${watchConUrl[0].current}`)
                            }
                            watchContinue[0].item.filter(fil => fil.vod_id == id).map(data => {
                                // window.open(`/vdo/${id}/ep/EP.${data.ep_num}?play=${data?.current || 0}`)
                            })
                        } else {
                            window.location.href = `/vdo/${id}/ep/EP.1`
                        }
                    })
            } else if (!localStorage.getItem('auth')) {
                window.location.href = `/vdo/${id}/ep/EP.1`
                // window.open(`/vdo/${id}/ep/EP.1`)
            }
        }
    }

    const renderMenu = props.datas.data.res_data.vod.map(data => {
        const renderVdo = (x, fn) => {
            return (
                data.item.map(vdo => {
                    return (
                        <Link >
                            <div className='box not-A'>
                                <img className='subbanner-img pl-2 pr-2 pt-1 pb-1' src={vdo[x]} alt={vdo.ch_name} onClick={() => {
                                    if (fn === 0)
                                        return vdoClick(vdo.vod_id)
                                    if (fn === 1)
                                        return continueClick(vdo.vod_id)
                                }} />
                                {/* <div class="overlay">
                                    {vdo.ch_name}
                                </div> */}
                            </div >
                        </Link >
                    )

                })
            )
        }
        if (data.playlist_type != 'x' && data.playlist_type != 'continue') {
            return (
                <>
                    <div className='mb-2 mr-4 ml-4'>
                        <div className='d-flex justify-content-between align-items-end'>
                            <span className="text-left ml-3 subbanner-l-text" style={{ color: 'white' }}>
                                {data.subtabs}
                            </span>
                            <Link to={`/sub/${data.subtabs}`}>
                                <span className="text-right mr-3 subbanner-r-text" style={{ color: '#fff200' }} >
                                    เพิ่มเติม
                                </span>
                            </Link>
                        </div>
                        <span className=''>
                        </span>
                    </div>
                    <Col>
                        <Slider className='mt-3 mb-3 mr-3 ml-3 position-relative' {...settingsone} afterChange={() => setIsDrag(false)} onSwipe={() => setIsDrag(true)}>
                            {renderVdo('imgY1', 0)}
                        </Slider>
                    </Col >
                </>
            )
        } else if (data.playlist_type == 'continue') {
            return (
                <>
                    <div className='mb-2 mr-4 ml-4'>
                        <div className='d-flex justify-content-between align-items-end'>
                            <span className="text-left ml-3 subbanner-l-text" style={{ color: 'white' }}>
                                {data.subtabs}
                            </span>
                            <Link to={`/sub/${data.subtabs}`}>
                                <span className="text-right mr-3 subbanner-r-text" style={{ color: '#fff200' }} >
                                    เพิ่มเติม
                                </span>
                            </Link>
                        </div>
                        <span className=''>
                        </span>
                    </div>
                    <Col>
                        <Slider className='mt-3 mb-3 mr-3 ml-3 position-relative' {...settingstwo} afterChange={() => setIsDrag(false)} onSwipe={() => setIsDrag(true)}>
                            {renderVdo('imgX1', 1)}
                        </Slider>
                    </Col >
                </>
            )
        } else {
            if(data.subtabs != '-') {
                return (
                    <>
                        <div className='mb-2 mr-4 ml-4'>
                            <div className='d-flex justify-content-between align-items-end'>
                                <span className="text-left ml-3 subbanner-l-text" style={{ color: 'white' }}>
                                    {data.subtabs}
                                </span>
                                <Link to={`/sub/${data.subtabs}`}>
                                    <span className="text-right mr-3 subbanner-r-text" style={{ color: '#fff200' }} >
                                        เพิ่มเติม
                                    </span>
                                </Link>
                            </div>
                            <span className=''>
                            </span>
                        </div>
                        <Col>
                            <Slider className='mt-3 mb-3 mr-3 ml-3 position-relative' {...settingstwo} afterChange={() => setIsDrag(false)} onSwipe={() => setIsDrag(true)}>
                                {renderVdo('imgX1', 0)}
                            </Slider>
                        </Col >
                    </>
                )
            } else {
                return (
                    <>
                    <div className='mb-2 mr-4 ml-4'>
                        <div className='d-flex justify-content-between align-items-end'>
                        </div>
                        <span className=''>
                        </span>
                    </div>
                    </>
                )
            }
        }
    })
    return (
        <>
            <div style={{textAlign:"center"}}>
            <amp-ad width="100vw" height="320"
                type="adsense"
                data-ad-client="ca-pub-6818646705911742"
                data-ad-slot="5427278033"
                data-auto-format="rspv"
                data-full-width="">
                <div overflow=""></div>
            </amp-ad>
            </div>
            <div className='bg-blank'>
                <Container>
                    {renderMenu}
                </Container>
            </div>
        </>
    );
}

export default Body;
