import React, { useState, useEffect, useRef } from 'react';
import img from '../../assets/img/body/MVHUB+-1.png'
import open from '../../assets/img/body/svg/chevron_left_white_48dp.svg'
import close from '../../assets/img/body/svg/chevron_right_white_48dp.svg'
// import play_btn from '../../assets/img/body/svg/play_arrow_white_36dp.svg'
// import pause_btn from '../../assets/img/body/svg/pause_white_36dp.svg'
// import replay_10 from '../../assets/img/body/svg/replay_10_white_36dp.svg'
// import forward_10 from '../../assets/img/body/svg/forward_10_white_36dp.svg'
// import volume_up_btn from '../../assets/img/body/svg/volume_up_white_36dp.svg'
// import volume_down_btn from '../../assets/img/body/svg/volume_down_white_36dp.svg'
// import volume_off_btn from '../../assets/img/body/svg/volume_off_white_36dp.svg'
// import fullscreen_btn from '../../assets/img/body/svg/fullscreen_white_36dp.svg'
// import exit_fullscreen_btn from '../../assets/img/body/svg/fullscreen_exit_white_36dp.svg'
// import playlist_btn from '../../assets/img/body/svg/format_list_numbered_white_36dp.svg'
// import subtitle_btn from '../../assets/img/body/svg/subtitles_white_36dp.svg'
// import setting_btn from '../../assets/img/body/svg/settings_white_36dp.svg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // carousel css
import "../../assets/css/fairplay.css"
import Slider from "react-slick";
import axios from 'axios';

export default function FairPlay(props) {
    var keySystem;
    var certificate;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    //var serverCertificatePath = 'http://localhost/web/fps/dev_certificate.der'; // ADAPT: This is the path to the fps certificate on your server.
    //var serverCertificatePath = 'http://localhost/web/fps/prod_fairplay.cer';
    //var serverCertificatePath = 'http://fps1.drm.dev.hk3.tvb.com/fps/prod_fairplay.cer';
    //var serverProcessSPCPath  = 'http://fps1.drm.dev.hk3.tvb.com/wsgi/ksm';     // ADAPT: This is the path/URL to the keyserver module that processes the SPC and returns a CKC

    function stringToArray(string) {
        var buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
        var array = new Uint16Array(buffer);
        for (var i = 0, strLen = string.length; i < strLen; i++) {
            array[i] = string.charCodeAt(i);
        }
        return array;
    }

    function arrayToString(array) {
        var uint16array = new Uint16Array(array.buffer);
        return String.fromCharCode.apply(null, uint16array);
    }

    function base64DecodeUint8Array(input) {
        var raw = window.atob(input);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++)
            array[i] = raw.charCodeAt(i);

        return array;
    }

    function base64EncodeUint8Array(input) {
        var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;

        while (i < input.length) {
            chr1 = input[i++];
            chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
            chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
                keyStr.charAt(enc3) + keyStr.charAt(enc4);
        }
        return output;
    }

    function waitForEvent(name, action, target) {
        target.addEventListener(name, function () {
            action(arguments[0]);
        }, false);
    }

    function loadCertificate() {
        var request = new XMLHttpRequest();
        request.responseType = 'arraybuffer';
        request.addEventListener('load', onCertificateLoaded, false);
        request.addEventListener('error', onCertificateError, false);
        request.open('GET', 'https://fp.miratech.io/prod_fairplay.cer', true);
        request.setRequestHeader('Pragma', 'Cache-Control: no-cache');
        request.setRequestHeader("Cache-Control", "max-age=0");
        request.send();
    }

    function onCertificateLoaded(event) {
        var request = event.target;
        // console.log('certificate is ', request)
        certificate = new Uint8Array(request.response);
        // console.log('Certificate loaded, len:' + certificate.length, 'and', request.response);
        startVideo();
    }

    function onCertificateError(event) {
        var request = event.target;
        // window.console.error('Failed to retrieve the server certificate.')
    }

    function extractContentId(initData) {
        // contentId is passed up as a URI, from which the host must be extracted:
        let kuri = arrayToString(initData);
        // console.debug(kuri);
        let kuri_parts = kuri.split("://", 2);
        // console.debug("len: " + kuri_parts.length + ", " + kuri_parts)
        let contentId = kuri_parts[1];
        // console.debug('extracted contentId: ' + contentId);
        return contentId;

        /*
                    var link = document.createElement('a');
                    link.href = contentId;
                    return link.hostname;
        */
    }

    function concatInitDataIdAndCertificate(initData, id, cert) {
        if (typeof id == "string")
            id = stringToArray(id);
        // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
        let offset = 0;
        let buffer = new ArrayBuffer(initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength);
        let dataView = new DataView(buffer);

        let initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
        initDataArray.set(initData);
        offset += initData.byteLength;

        dataView.setUint32(offset, id.byteLength, true);
        offset += 4;

        var idArray = new Uint16Array(buffer, offset, id.length);
        idArray.set(id);
        offset += idArray.byteLength;

        dataView.setUint32(offset, cert.byteLength, true);
        offset += 4;

        var certArray = new Uint8Array(buffer, offset, cert.byteLength);
        certArray.set(cert);

        return new Uint8Array(buffer, 0, buffer.byteLength);
    }

    function selectKeySystem() {
        if (window.WebKitMediaKeys.isTypeSupported("com.apple.fps.1_0", "video/mp4")) {
            keySystem = "com.apple.fps.1_0";
        }
        else {
            throw "Key System not supported";
        }
        // console.log('selectKeySystem: ' + keySystem);
    }

    function startVideo() {
        var video = document.getElementsByTagName('video')[0];
        video.addEventListener('webkitneedkey', onneedkey, false);
        video.addEventListener('error', onerror, false);
        // ADAPT: there must be logic here to fetch/build the appropriate m3u8 URL
        //video.src = 'http://localhost/web/fps/tests/Muxed/encrypted/prog_index.m3u8';
        video.src = document.getElementById('videosrc').value
        // console.log('starting video');
        video.play();
    }

    function onerror(event) {
        window.console.error('A video playback error occurred')
    }

    function onneedkey(event) {
        // console.log('webkit onneedkey');
        let video = event.target;
        let initData = event.initData;
        let contentId = extractContentId(initData);
        // console.log('contentId: ' + contentId);
        initData = concatInitDataIdAndCertificate(initData, contentId, certificate);
        if (!video.webkitKeys) {
            selectKeySystem();
            // video.webkitSetMediaKeys(new WebKitMediaKeys(keySystem));
            // eslint-disable-next-line no-undef
            video.webkitSetMediaKeys(new WebKitMediaKeys("com.apple.fps.1_0"))
            // video.setMediaKeys(new MediaKeys(keySystem))
        }

        if (!video.webkitKeys)
            throw "Could not create MediaKeys";

        var keySession = video.webkitKeys.createSession("video/mp4", initData);
        if (!keySession)
            throw "Could not create key session";

        keySession.contentId = contentId;
        waitForEvent('webkitkeymessage', licenseRequestReady, keySession);
        waitForEvent('webkitkeyadded', onkeyadded, keySession);
        waitForEvent('webkitkeyerror', onkeyerror, keySession);
    }

    function licenseRequestReady(event) {
        // console.log('licenseRequestReady');
        var session = event.target;
        var message = event.message;
        var request = new XMLHttpRequest();
        var sessionId = event.sessionId;
        request.responseType = 'text';
        request.session = session;
        request.addEventListener('load', licenseRequestLoaded, false);
        request.addEventListener('error', licenseRequestFailed, false);
        var params = 'spc=' + base64EncodeUint8Array(message) + '&assetid=' + encodeURIComponent(session.contentId);
        request.open('POST', 'https://fp.miratech.io/fps/ksm', true);
        request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        request.setRequestHeader("X-Request-Device-ID", "hex");
        request.setRequestHeader("X-User-Token", '<bossID>');
        request.setRequestHeader("X-Service-ID", 'atc137bfk');
        request.setRequestHeader("X-Client-Platform", 'html5');
        request.send(params);
    }

    function licenseRequestLoaded(event) {
        var request = event.target;
        var session = request.session;
        // response can be of the form: '\n<ckc>base64encoded</ckc>\n'
        // so trim the excess:
        let keyText = request.responseText.trim();
        if (keyText.substr(0, 5) === '<ckc>' && keyText.substr(-6) === '</ckc>')
            keyText = keyText.slice(5, -6);
        let key = base64DecodeUint8Array(keyText);
        session.update(key);

        // // Get device ID from response header
        // let dvId = this.getResponseHeader("X-Auth-Device-ID");
        // var ele = document.getElementById('dv_id');
        // if (dvId != undefined && dvId.length > 0) {
        //     ele.style.display = 'block';
        //     ele.textContent = 'Device ID: ' + dvId;
        // }
        // else {
        //     ele.style.display = 'none';
        // }
    }

    function licenseRequestFailed(event) {
        window.console.error('The license request failed.');
    }

    function onkeyerror(event) {
        window.console.error('A decryption key error was encountered');
    }

    function onkeyadded(event) {
        window.console.log('Decryption key was added to session.');
    }

    //video-controls++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    const videoPlayer = useRef(null)
    const video = useRef(null)
    const nextBtnRef = useRef(null)

    //Ep Card
    const [isDrag, setIsDrag] = useState(false)
    const disableEp = () => {
        document.getElementById('ep').setAttribute('show', 'false')
    }
    const enableEp = () => {
        document.getElementById('ep').setAttribute('show', 'true')
    }

    const settingsone = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
    };

    //carousel
    const epCard = useRef(null)
    const slider = useRef(null)

    const epClick = (id, ep_name, premium) => {
        if (isDrag == true) {
            return null
        } else {
            if (premium == '1') {
                return window.location.href = `/vdo/${id}/ep/${ep_name}`
            } else {
                return alert('กรุณาสมัครแพ็คเกจ')
            }
        }
    }
    const renderEp = props.datas.current.episode.map((data) => {
        return (
            <div className='position-relative' onClick={() => epClick(props.id, data.ep_name, data.premium)}>
                <img style={{ width: '100%' }} src={data.ep_img} alt='' />
                <div className='nameTag'>{data.ep_name}</div>
            </div>
        )
    })

    const videoEnded = () => {
        if (props.nextUrl.split('/').pop() !== 'EP.0')
            window.location.href = props.nextUrl
    }

    const videoOnpress = (event) => {
        if (event.code == 'Digit0') {
            video.current.currentTime = 0
        }
        if (event.code == 'Digit1') {
            video.current.currentTime = (10 * video.current.duration) / 100
        }
        if (event.code == 'Digit2') {
            video.current.currentTime = (20 * video.current.duration) / 100
        }
        if (event.code == 'Digit3') {
            video.current.currentTime = (30 * video.current.duration) / 100
        }
        if (event.code == 'Digit4') {
            video.current.currentTime = (40 * video.current.duration) / 100
        }
        if (event.code == 'Digit5') {
            video.current.currentTime = (50 * video.current.duration) / 100
        }
        if (event.code == 'Digit6') {
            video.current.currentTime = (60 * video.current.duration) / 100
        }
        if (event.code == 'Digit7') {
            video.current.currentTime = (70 * video.current.duration) / 100
        }
        if (event.code == 'Digit8') {
            video.current.currentTime = (80 * video.current.duration) / 100
        }
        if (event.code == 'Digit9') {
            video.current.currentTime = (90 * video.current.duration) / 100
        }
        if (event.code == 'KeyM') {
            if (video.current.muted == true) {
                video.current.muted = false
            } else {
                video.current.muted = true
            }
        }
        if (event.code == 'Space') {
            video.current.paused ? video.current.play() : video.current.pause()
        }
        if (event.code == 'ArrowRight') {
            video.current.currentTime += 10
        }
        if (event.code == "ArrowLeft") {
            video.current.currentTime -= 10
        }
        if (event.code == 'ArrowUp') {
            video.current.volume = video.current.volume >= 0.9 ? 1.0 : parseFloat(video.current.volume + 0.1).toFixed(1)
        }
        if (event.code == 'ArrowDown') {
            video.current.volume = video.current.volume <= 0.1 ? 0.0 : parseFloat(video.current.volume - 0.1).toFixed(1)
        }
        if (event.code == 'KeyF') {
            if (document.fullscreenElement) {
                document.exitFullscreen()
            } else {
                video.current.requestFullscreen()
            }
        }
    }

    document.addEventListener('keydown', (event) => videoOnpress(event))

    useEffect(() => {
        let { slideCount } = slider.current.innerSlider.state

        const scroll = (e) => {
            if (slider === null)
                return 0
            if (e.wheelDelta > 0) {
                if (slider.current.innerSlider.state.currentSlide != 0)
                    slider.current.slickPrev()
            } else {
                if (parseInt(slider.current.innerSlider.state.currentSlide + 6) != parseInt(slideCount))
                    slider.current.slickNext()
            }
            // e.wheelDelta > 0 ? (
            //     slider.current.slickPrev()
            // ) : (
            //     slider.current.slickNext()
            // )
        }

        const vdoPlay = async () => {
            loadCertificate()
            epCard.current.addEventListener('wheel', scroll, true)
            //continueWatching
            if (params.get('play')) {
                video.current.addEventListener('loadedmetadata', () => video.current.currentTime = parseInt(params.get('play')), false)
            }
            //nextButton nextBtn
            try{
                if (props.nextUrl.split('/').pop() !== 'EP.0')
                    video.current.addEventListener('timeupdate', () => {
                        if (video.current.currentTime < (video.current.duration - 90)) {
                            nextBtnRef?.current.setAttribute('show', 'false')
                        } else {
                            nextBtnRef?.current.setAttribute('show', 'true')
                        }
                    })
            } catch(err) {
                return
            }
            setInterval(async () => {
                if (localStorage.getItem('auth')) {
                    await axios.put(`${process.env.REACT_APP_API_CONTENT2}/update/continuewatching`, {
                        "content_id": String(props.ep[0]),
                        "ep_id": String(props.ep[1]),
                        "type": "serie",
                        "current": String(video.current.currentTime.toFixed(0)) + '000',
                        "duration_time": String(video.current.duration.toFixed(0)) + '000'
                    }, {
                        headers: {
                            Authorization: `Bearer ${window.localStorage.getItem('auth')}`
                        },
                    }).then(() => {
                        // console.log(result)
                    })
                }
            }, 120 * 1000)
        }
        vdoPlay()
    }, [])
    return (
        <>
            <div className='video-container'>
                <input type="text" id="videosrc" size='128' maxLength='512' style={{ display: 'none' }}
                    defaultValue={props.url} />
                <div className='text-yellow' id="dv_id" style={{ display: 'none' }}></div>
                <img className="top-right logo" src={img} alt='mvtv' onClick={() => window.location.href = '/'} />
                <div className='video-player' ref={videoPlayer}>
                    <video
                        ref={video}
                        className="shaka video"
                        autoPlay
                        preload="auto"
                        controls
                        poster={props.poster}
                        onEnded={videoEnded}
                    />
                    <button ref={nextBtnRef} className='next-btn' show='false' onClick={() => window.location.href = props.nextUrl}>ตอนต่อไป</button>
                    <div className='enable-ep' id='enable-ep' show='true' onMouseOver={enableEp}>
                        <img src={open} />
                    </div>
                    <div ref={epCard} id='ep' className='ep' show='false' onMouseLeave={disableEp}>
                        <div className='disable-ep'
                            onClick={disableEp}
                        >
                            <span>
                                <img src={close} alt='disable' />
                            </span>
                            <span>
                                <a>
                                    ปิด
                                </a>
                            </span>
                        </div>
                        <div className='position-relative'>
                            <Slider ref={slider} className='mt-3 mb-3 mr-3 ml-3 position-absolute' {...settingsone} afterChange={() => setIsDrag(false)} onSwipe={() => setIsDrag(true)}>
                                {renderEp}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}